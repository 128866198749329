import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const Schedule = React.lazy(() => import("./views/pages/schedule/Schedule"));
const Zoom = React.lazy(() => import("./views/pages/zoom/Zoom"));
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const LoggedIn = sessionStorage.getItem("isLoggedIn");
const ScheduleAvailable = sessionStorage.getItem("isScheduleAvailable");

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/schedule"
                name="Schedule"
              render={(props) => <Schedule {...props} />}
            />
            <Route
              exact
              path="/zoom"
              name="Zoom"
              render={(props) => <Zoom {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            {LoggedIn ? (
              <>
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              </>
            ) : (
              <>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Redirect to="/login" />
              </>
            )}
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
